import React, { useEffect, useState, useContext, useRef } from 'react';
import { AppContext } from 'context/appContext';
import {
	SettingHeaders,
	SettingPageHeaders,
	SettingSectionHeaders,
	SettingTabHeaders,
} from 'hooks/useSettingsController';
import 'aframe';
// import { LidarVisualizerSetup } from 'utils/aframe/lidarVisualizer';

const RemoteNavOverlay = () => {
	const { settingsController } = useContext(AppContext);
	const shouldShow = useRef(true);
	useEffect(() => {
		shouldShow.current =
			settingsController.settings['App settings'].children[
				SettingPageHeaders.AUGMENTED_REALITY
			].children[SettingTabHeaders.GENERAL].children[
				SettingSectionHeaders.AUGMENTED_REALITY
			].children[SettingHeaders.AR_ENABLE_NAV_LINES].value;
	}, [settingsController.settings]);

	useEffect(() => {
		// When the browser is severely starved of resources, it will lose its control over the GPU temporarily.
		// This causes webgl to break, which makes threeJS and A-frame crash.
		// This crash causes our driving-line overlays to become opaque white boxes, meaning the pilot can no longer
		// see the video feeds!
		// webGlCrashed will disable the driving lines feature permanently for the rest of the current session when the issue occurs.
		// (We could recover automatically instead, but retrying without fixing the root cause will likely lead to issue re-occurring)
		const canvasses = document.querySelectorAll('canvas');
		for (let i = 0; i < canvasses.length; i++) {
			canvasses[i].addEventListener('webglcontextlost', () => {
				shouldShow.current = false;
				(window as any).webGlCrashed = true;
			});
		}
		window.addEventListener('webglcontextlost', () => {
			shouldShow.current = false;
			(window as any).webGlCrashed = true;
		});
	}, []);

	return (
		<>
			{/* This just registers the component but doesn't render anything
			<LidarVisualizerSetup /> */}

			<div
				id="primary_scene_container"
				style={{
					zIndex: 10,
					position: 'absolute',
					width: '100%',
					height: '100%',
					display: shouldShow.current && !(window as any).webGlCrashed ? 'block' : 'none',
				}}
			>
				<a-scene id="primary_scene" embedded xr-mode-ui="enabled: false">
					<a-entity id="top_rig" position="0 1.57 0" rotation="-21 0 0">
						<a-camera
							id="front_camera"
							fov={(1080 / Math.sqrt(1920 * 1920 + 1080 * 1080)) * 170}
							zoom="1"
							position="0 0 0"
							active="true"
							look-controls="enabled: false"
							wasd-controls="enabled: false"
						/>
					</a-entity>
					<a-entity
						id="primary_floor"
						geometry="primitive: plane; height: 10; width: 10"
						position="0 0 0"
						rotation="-90 0 0"
						material="color: green; opacity: 0.0"
					>
						<a-entity
							class="navigation-line"
							navigation-line="startPoint: -0.225 0; linewidth: 9; shadow: true"
						/>
						<a-entity
							class="navigation-line"
							navigation-line="startPoint: 0.225 0; linewidth: 9; shadow: true"
						/>

						{/* Add LIDAR visualization here
						<a-entity
							class="lidar-visualization"
							position="0 0 0" // Adjust to be slightly above the floor
							// scale="1 1 1"
							rotation="90 0 0"
							lidar-visualizer="origin: 0 0 0; layers: false"
						/> */}
					</a-entity>
				</a-scene>
			</div>
		</>
	);
};

export default RemoteNavOverlay;
