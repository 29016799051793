import React, { useEffect, useContext, useRef, useState } from 'react';
import {
	SettingHeaders,
	SettingPageHeaders,
	SettingPageSectionHeaders,
	SettingSectionHeaders,
	SettingTabHeaders,
} from 'hooks/useSettingsController';
import { AppContext } from 'context/appContext';
import rotationImg from '../../../../images/rotation.svg';
import 'aframe';
import { LidarVisualizerSetup } from 'utils/aframe/lidarVisualizer';

const NavigationNavOverlay = () => {
	const { settingsController } = useContext(AppContext);
	const shouldShow = useRef(true);
	useEffect(() => {
		shouldShow.current =
			settingsController.settings['App settings'].children[
				SettingPageHeaders.AUGMENTED_REALITY
			].children[SettingTabHeaders.GENERAL].children[
				SettingSectionHeaders.AUGMENTED_REALITY
			].children[SettingHeaders.AR_ENABLE_NAV_LINES].value;
	}, [settingsController.settings]);

	useEffect(() => {
		// When the browser is severely starved of resources, it will lose its control over the GPU temporarily.
		// This causes webgl to break, which makes threeJS and A-frame crash.
		// This crash causes our driving-line overlays to become opaque white boxes, meaning the pilot can no longer
		// see the video feeds!
		// webGlCrashed will disable the driving lines feature permanently for the rest of the current session when the issue occurs.
		// (We could recover automatically instead, but retrying without fixing the root cause will likely lead to issue re-occurring)
		const canvasses = document.querySelectorAll('canvas');
		for (let i = 0; i < canvasses.length; i++) {
			canvasses[i].addEventListener('webglcontextlost', () => {
				shouldShow.current = false;
				(window as any).webGlCrashed = true;
			});
		}
		window.addEventListener('webglcontextlost', () => {
			shouldShow.current = false;
			(window as any).webGlCrashed = true;
		});
	}, []);

	return (
		<>
			{/* This just registers the component but doesn't render anything */}
			<LidarVisualizerSetup />
			<div
				style={{
					zIndex: 3,
					position: 'absolute',
					width: '100%',
					height: '100%',
					display: shouldShow.current && !(window as any).webGlCrashed ? 'block' : 'none',
				}}
			>
				<a-scene id="secondary_scene" embedded xr-mode-ui="enabled: false">
					<a-entity id="bottom_rig" position="0 1.03 0" rotation="-81 0 0">
						<a-camera
							id="bottom_camera"
							fov="61" // FOV not accurate to reality, but used to compensate for the distortion of the camera as there is no time for a proper fix currently
							position="0 0 0"
							active="true"
							look-controls="enabled: false"
							wasd-controls="enabled: false"
						/>
					</a-entity>

					<a-entity
						id="secondary_floor"
						geometry="primitive: plane; height: 1; width: 1"
						position="0 0 0"
						rotation="-90 0 0"
						material="color: green; opacity: 0"
					>
						<a-entity
							class="navigation-line"
							navigation-line="startPoint: -0.225 0; linewidth: 9; shadow: true"
						/>
						<a-entity
							class="navigation-line"
							navigation-line="startPoint: -0.225 0; linewidth: 9; shadow: true"
						>
							<a-plane
								id="stand-still-rotation"
								position="0 0 0.1"
								width="0.5"
								height="0.5"
								material={`src: ${rotationImg}; transparent: true; visible: false`}
							/>
						</a-entity>
						<a-entity
							class="navigation-line"
							navigation-line="startPoint: 0.225 0; linewidth: 9; shadow: true"
						/>

						{/* Add LIDAR visualization here */}
						<a-entity
							class="lidar-visualization"
							position="0 0.20 0" // Adjust to be slightly above the floor
							// scale="1 1 1"
							rotation="90 0 0"
							lidar-visualizer="origin: 0 0 0; layers: false"
						/>
					</a-entity>
				</a-scene>
			</div>
		</>
	);
};

export default NavigationNavOverlay;
