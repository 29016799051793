import React, { useEffect } from 'react';
import 'aframe';

// Configuration object for LIDAR visualization (similar to NavLineConfig)
const LidarConfig = {
	pointSize: 0.01,
	maxPoints: 1000,
	colorByIntensity: true,
	minIntensity: 0,
	maxIntensity: 100,
	heightColorCoding: false,
	rotationSpeed: 0.5,
	downsampleFactor: 4,
	sendRate: 10, // Hz
};

// Debug configuration for LIDAR positioning
const LidarDebugConfig = {
	lidarX: 0,
	lidarY: 0,
	lidarZ: 0,
	lidarYaw: 0,
};

// Function to set debug LIDAR position parameters
export function setLidarPosition(x: number, y: number, z: number, yaw = null) {
	LidarDebugConfig.lidarX = x || 0;
	LidarDebugConfig.lidarY = y || 0;
	LidarDebugConfig.lidarZ = z || 0;

	if (yaw !== null) {
		LidarDebugConfig.lidarYaw = yaw;
	}

	console.log(
		`LIDAR position set to: X=${LidarDebugConfig.lidarX}, Y=${LidarDebugConfig.lidarY}, Z=${LidarDebugConfig.lidarZ}, Yaw=${LidarDebugConfig.lidarYaw}`
	);

	// Update visualization with new settings
	emitLidarConfigUpdate();

	return LidarDebugConfig;
}

// Function to get current debug LIDAR position parameters
export function getLidarPosition() {
	return { ...LidarDebugConfig };
}

// Function to emit configuration update (similar to NavLine)
export function emitLidarConfigUpdate() {
	const scenes = document.querySelectorAll('a-scene');
	scenes.forEach(function (scene) {
		scene.querySelectorAll('.lidar-visualization').forEach(function (lidarEl) {
			//@ts-ignore
			lidarEl.emit('lidar-config-update');
		});
	});
}

// Helper functions to update LIDAR visualization with new data
//@ts-ignore
export function updateLidarVisualization(type, jsonData) {
	//@ts-ignore
	if (window.lidarVisualizer) {
		//@ts-ignore
		window.lidarVisualizer.updatePoints(type, jsonData);
	} else {
		console.warn('LIDAR visualizer not initialized yet');
	}
}

// Register the AFRAME component for LIDAR visualization
//@ts-ignore
export function registerLidarVisualizer() {
	if (AFRAME.components['lidar-visualizer']) return;

	AFRAME.registerComponent('lidar-visualizer', {
		schema: {
			// Similar schema structure to NavLine
			origin: {
				type: 'vec3',
				default: { x: 0, y: 0, z: 0 },
				parse: AFRAME.utils.coordinates.parse,
			},
			layers: { type: 'boolean', default: false },
		},

		didInit: false,
		firstRenders: 0,

		// Shader components
		vertexShader: null,
		fragmentShader: null,
		material: null,

		// Geometry elements
		points: null,
		layerPoints: null,
		geometry: null,
		layerGeometries: null,
		pointCloud: null,
		layerPointClouds: null,

		// Vertex data
		vertexIndices: null,
		intensityAttr: null,

		// Ring buffer for streaming updates
		ringBuffer: null,

		init: function () {
			// Initialize the ring buffer for streaming point updates
			//@ts-ignore
			this.ringBuffer = {
				positions: new Float32Array(LidarConfig.maxPoints * 3),
				intensities: new Float32Array(LidarConfig.maxPoints),
				currentIndex: 0,
				count: 0,
			};

			// Define parameters similar to NavLine
			let pointOpacity = 0.75;
			let numLayers = 3; // For multiple visualization layers
			let layerOpacityStep = pointOpacity / numLayers;

			// Initialize points arrays
			//@ts-ignore
			this.points = [];
			//@ts-ignore
			this.layerPoints = [];

			for (let i = 1; i <= numLayers; i++) {
				//@ts-ignore
				this.layerPoints[i] = [];
			}

			// Create vertex shader (similar to NavLine)
			//@ts-ignore
			this.vertexShader = `
            attribute float index;
            attribute float intensity;
            varying float vIndex;
            varying float vIntensity;
            void main() {
                vIndex = index;
                vIntensity = intensity;
                gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
                gl_PointSize = ${LidarConfig.pointSize} * (1.0 - vIndex / ${LidarConfig.maxPoints}.0);
            }
            `;

			// Create fragment shader with CORRECTED function declarations
			// The previous shader error was due to functions being declared incorrectly
			//@ts-ignore
			this.fragmentShader = `
            uniform vec3 color;
            uniform float length;
            uniform float startingOpacity;
            uniform bool colorByIntensity;
            varying float vIndex;
            varying float vIntensity;
            
            // Function for hue to RGB conversion
            float hue2rgb(float p, float q, float t) {
                if (t < 0.0) t += 1.0;
                if (t > 1.0) t -= 1.0;
                if (t < 1.0/6.0) return p + (q - p) * 6.0 * t;
                if (t < 1.0/2.0) return q;
                if (t < 2.0/3.0) return p + (q - p) * (2.0/3.0 - t) * 6.0;
                return p;
            }
            
            // Function for HSL to RGB conversion
            vec3 hslToRgb(float h, float s, float l) {
                float r, g, b;
                
                if (s == 0.0) {
                    r = g = b = l; // achromatic
                } else {
                    float q = l < 0.5 ? l * (1.0 + s) : l + s - l * s;
                    float p = 2.0 * l - q;
                    r = hue2rgb(p, q, h + 1.0/3.0);
                    g = hue2rgb(p, q, h);
                    b = hue2rgb(p, q, h - 1.0/3.0);
                }
                
                return vec3(r, g, b);
            }
            
            void main() {
                float opacity = startingOpacity * (1.0 - vIndex / length);
                vec3 finalColor = color;
                
                if (colorByIntensity) {
                    // Rainbow color mapping based on intensity
                    float h = (1.0 - vIntensity) * 240.0 / 360.0; // Hue: blue (240) to red (0)
                    finalColor = hslToRgb(h, 1.0, 0.5);
                }
                
                gl_FragColor = vec4(finalColor, opacity);
            }
            `;

			// Create shader material (using same pattern as NavLine)
			let lidarColor = '#56AE4D'; // Same as NavLine for consistency

			// In the init function, replace the shader material with a simple material
			//@ts-ignore
			this.material = new THREE.PointsMaterial({
				color: '#FF0000', // Bright red for visibility
				size: 0.02, // Larger size
				transparent: true,
				opacity: 0.8, // Full opacity
			});

			// try {
			// 	//@ts-ignore
			// 	this.material = new THREE.ShaderMaterial({
			// 		uniforms: {
			// 			//@ts-ignore
			// 			color: { value: new THREE.Color(lidarColor).convertLinearToSRGB() },
			// 			length: { value: LidarConfig.maxPoints },
			// 			startingOpacity: { value: pointOpacity },
			// 			colorByIntensity: { value: LidarConfig.colorByIntensity },
			// 		},
			// 		vertexShader: this.vertexShader,
			// 		fragmentShader: this.fragmentShader,
			// 		transparent: true,
			// 		vertexColors: true,
			// 	});
			// } catch (e) {
			// 	console.error('Error creating shader material:', e);
			// 	// Fallback to basic material if shader fails
			// 	//@ts-ignore
			// 	this.material = new THREE.PointsMaterial({
			// 		color: lidarColor,
			// 		size: LidarConfig.pointSize,
			// 		transparent: true,
			// 		opacity: pointOpacity,
			// 	});
			// }

			// Create empty geometries and point clouds
			//@ts-ignore
			this.vertexIndices = new Float32Array(LidarConfig.maxPoints);
			//@ts-ignore
			this.intensityAttr = new Float32Array(LidarConfig.maxPoints);

			for (let i = 0; i < LidarConfig.maxPoints; i++) {
				//@ts-ignore
				this.vertexIndices[i] = i;
				//@ts-ignore
				this.intensityAttr[i] = 0.5; // Default intensity
				//@ts-ignore
				this.points[i] = new THREE.Vector3(0, 0, 0);
			}

			//@ts-ignore
			this.geometry = new THREE.BufferGeometry().setFromPoints(this.points);
			//@ts-ignore
			this.geometry.setAttribute('index', new THREE.BufferAttribute(this.vertexIndices, 1));
			//@ts-ignore
			this.geometry.setAttribute('intensity', new THREE.BufferAttribute(this.intensityAttr, 1));

			//@ts-ignore
			this.pointCloud = new THREE.Points(this.geometry, this.material);

			// Create layer geometries if using layers
			//@ts-ignore
			this.layerGeometries = [];
			//@ts-ignore
			this.layerPointClouds = [];

			// if (this.data.layers) {
			// 	for (let i = 1; i <= numLayers; i++) {
			// 		for (let j = 0; j < LidarConfig.maxPoints; j++) {
			// 			//@ts-ignore
			// 			this.layerPoints[i][j] = new THREE.Vector3(0, 0, 0);
			// 		}

			// 		//@ts-ignore
			// 		this.layerGeometries[i] = new THREE.BufferGeometry().setFromPoints(this.layerPoints[i]);
			// 		//@ts-ignore
			// 		this.layerGeometries[i].setAttribute(
			// 			'index',
			// 			//@ts-ignore
			// 			new THREE.BufferAttribute(this.vertexIndices, 1)
			// 		);
			// 		//@ts-ignore
			// 		this.layerGeometries[i].setAttribute(
			// 			'intensity',
			// 			//@ts-ignore
			// 			new THREE.BufferAttribute(this.intensityAttr, 1)
			// 		);

			// 		//@ts-ignore
			// 		const layerMaterial = this.material.clone();
			// 		layerMaterial.uniforms.startingOpacity.value = pointOpacity - i * layerOpacityStep;

			// 		//@ts-ignore
			// 		this.layerPointClouds[i] = new THREE.Points(this.layerGeometries[i], layerMaterial);
			// 	}
			// }

			// Listen for configuration update events like NavLine
			//@ts-ignore
			this.el.sceneEl.addEventListener('lidar-config-update', this.renderPoints.bind(this));

			// Make this component accessible globally for data updates
			//@ts-ignore
			window.lidarVisualizer = this;

			this.didInit = true;
			this.renderPoints();
		},

		// Update LIDAR points from JSON data
		//@ts-ignore
		updatePoints: function (type, jsonData) {
			if (!this.didInit) return;

			try {
				// Parse received data if it's a string
				const data = typeof jsonData === 'string' ? JSON.parse(jsonData) : jsonData;
				// console.debug(data);
				// Check for expected format (matching your provided JSON structure)
				if (type === 'laser_scan' && data) {
					// console.debug('RCM - updatePoints');
					const scan = data;
					const validPoints = Math.min(scan.points.length, LidarConfig.maxPoints);
					let index = 0;

					// Always prioritize debug values over scan values
					const lidarX =
						LidarDebugConfig.lidarX !== 0 ? LidarDebugConfig.lidarX : scan.lidar_x || 0;
					const lidarY =
						LidarDebugConfig.lidarY !== 0 ? LidarDebugConfig.lidarY : scan.lidar_y || 0;
					const lidarZ =
						LidarDebugConfig.lidarZ !== 0 ? LidarDebugConfig.lidarZ : scan.lidar_z || 0;
					const lidarYaw =
						LidarDebugConfig.lidarYaw !== 0 ? LidarDebugConfig.lidarYaw : scan.lidar_yaw || 0;

					// Process points similar to NavLine's approach
					for (let i = 0; i < scan.points.length; i += LidarConfig.downsampleFactor) {
						const range = scan.points[i];

						// Skip invalid measurements
						if (range <= 0 || range < scan.range_min || range > scan.range_max) continue;

						// Calculate angle for this point
						const angle = scan.angle_min + i * scan.angle_increment;

						// Set point in ring buffer
						//@ts-ignore
						const bufferIndex = (this.ringBuffer.currentIndex + index) % LidarConfig.maxPoints;

						// Calculate point position in LIDAR frame
						const pointX = range * Math.sin(angle);
						const pointY = range * Math.cos(angle);

						// Apply LIDAR offset and rotation based on our debug values
						// Calculate rotation if lidarYaw is provided
						const cosYaw = Math.cos(lidarYaw);
						const sinYaw = Math.sin(lidarYaw);

						const rotatedX = pointX * cosYaw - pointY * sinYaw;
						const rotatedY = pointX * sinYaw + pointY * cosYaw;

						//@ts-ignore
						this.ringBuffer.positions[bufferIndex * 3] = rotatedX + lidarX; // x with LIDAR offset
						//@ts-ignore
						this.ringBuffer.positions[bufferIndex * 3 + 1] = lidarY; // y with LIDAR offset
						//@ts-ignore
						this.ringBuffer.positions[bufferIndex * 3 + 2] = rotatedY + lidarZ; // z with LIDAR offset

						// Store intensity if available
						//@ts-ignore
						this.ringBuffer.intensities[bufferIndex] =
							scan.intensities && scan.intensities[i]
								? (scan.intensities[i] - LidarConfig.minIntensity) /
								  (LidarConfig.maxIntensity - LidarConfig.minIntensity)
								: 0.5;

						index++;
						if (index >= LidarConfig.maxPoints) break;
					}

					// Update current index and count
					//@ts-ignore
					this.ringBuffer.currentIndex =
						//@ts-ignore
						(this.ringBuffer.currentIndex + index) % LidarConfig.maxPoints;
					//@ts-ignore
					this.ringBuffer.count = Math.min(this.ringBuffer.count + index, LidarConfig.maxPoints);

					// Add these debug statements to the updatePoints function
					//@ts-ignore
					// console.log('RCM Ring buffer count:', this.ringBuffer.count);
					// //@ts-ignore
					// console.log('RCM Ring buffer current index:', this.ringBuffer.currentIndex);
					// console.log(
					// 	'RCM First few values in positions buffer:',
					// 	//@ts-ignore
					// 	Array.from({ length: 9 }).map((_, i) => this.ringBuffer.positions[i])
					// );

					// Trigger rendering update
					this.renderPoints();
				} else {
					console.warn('Received data is not in the expected laser_scan format');
				}
			} catch (error) {
				console.error('Error processing laser scan data:', error);
			}
		},

		// Render the point cloud (similar to renderPath in NavLine)
		renderPoints: function () {
			// console.debug('RCM - rendering points');
			if (!this.didInit) return;

			let data = this.data;
			let el = this.el;

			// Similar to the NavLine firstRenders workaround
			if (this.firstRenders < 3) {
				this.firstRenders++;
				// Force some points to appear to avoid the visibility bug
				for (let i = 0; i < 50; i++) {
					//@ts-ignore
					this.points[i].set(
						// (Math.random() - 0.5) * 5,
						// (Math.random() - 0.5) * 0.1,
						0,
						0,
						(Math.random() - 0.5) * 5
					);
				}

				// console.log(
				// 	'RCM First few values in points',
				// 	//@ts-ignore
				// 	Array.from({ length: 60 }).map((_, i) => this.points[i])
				// );
			} else {
				// Update from ring buffer
				//@ts-ignore
				for (let i = 0; i < this.ringBuffer.count; i++) {
					//@ts-ignore
					const bufferIndex = (this.ringBuffer.currentIndex + i) % LidarConfig.maxPoints;

					//@ts-ignore
					this.points[i].set(
						//@ts-ignore
						this.ringBuffer.positions[bufferIndex * 3] + data.origin.x,
						//@ts-ignore
						this.ringBuffer.positions[bufferIndex * 3 + 1] + data.origin.y,
						//@ts-ignore
						this.ringBuffer.positions[bufferIndex * 3 + 2] + data.origin.z
					);

					//@ts-ignore
					this.intensityAttr[i] = this.ringBuffer.intensities[bufferIndex];

					// // Update layer points with offsets (similar to NavLine shadows)
					// if (data.layers) {
					// 	for (let j = 1; j <= 3; j++) {
					// 		// Create pattern similar to NavLine shadow offsets
					// 		const offsetY = j * 0.01;
					// 		//@ts-ignore
					// 		if (this.layerPoints[j] && this.layerPoints[j][i]) {
					// 			//@ts-ignore
					// 			this.layerPoints[j][i].set(
					// 				//@ts-ignore
					// 				this.points[i].x,
					// 				//@ts-ignore
					// 				this.points[i].y + offsetY,
					// 				//@ts-ignore
					// 				this.points[i].z
					// 			);
					// 		}
					// 	}
					// }
				}

				// console.log(
				// 	'RCM First few values in points2',
				// 	//@ts-ignore
				// 	Array.from({ length: 60 }).map((_, i) => this.points[i])
				// );
			}

			// Update geometries (similar to NavLine)
			//@ts-ignore
			this.geometry.setFromPoints(this.points);

			// Update intensity attribute
			//@ts-ignore
			const intensityAttribute = this.geometry.getAttribute('intensity');
			if (intensityAttribute) {
				intensityAttribute.needsUpdate = true;
			}

			//@ts-ignore
			this.pointCloud.geometry = this.geometry;
			//@ts-ignore
			el.setObject3D('points', this.pointCloud);

			// // Update layer point clouds
			// if (data.layers) {
			// 	for (let i = 1; i <= 3; i++) {
			// 		//@ts-ignore
			// 		if (this.layerGeometries[i] && this.layerPoints[i]) {
			// 			//@ts-ignore
			// 			this.layerGeometries[i].setFromPoints(this.layerPoints[i]);

			// 			// Update layer intensity attributes
			// 			//@ts-ignore
			// 			const layerIntensityAttribute = this.layerGeometries[i].getAttribute('intensity');
			// 			if (layerIntensityAttribute) {
			// 				layerIntensityAttribute.needsUpdate = true;
			// 			}

			// 			//@ts-ignore
			// 			this.layerPointClouds[i].geometry = this.layerGeometries[i];
			// 			//@ts-ignore
			// 			el.setObject3D('layer' + i, this.layerPointClouds[i]);
			// 		}
			// 	}
			// }
		},
	});
}

// React component that just registers the A-Frame component
export const LidarVisualizerSetup: React.FC = () => {
	React.useEffect(() => {
		registerLidarVisualizer();
	}, []);

	return null; // This component doesn't render anything
};

// Expose debug functions globally
//@ts-ignore
window.setLidarPosition = setLidarPosition;
//@ts-ignore
window.getLidarPosition = getLidarPosition;
