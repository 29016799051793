import { IceTransportPolicy, IceTransportProtocol, WebRTCSessionConfiguration } from 'webRTC/types';

export type LocalTrackLabel = PilotPrimaryCamera.LOCAL;

export type RemoteTrackKey =
	| RobotPrimaryCamera.WIDE_CAM
	| RobotPrimaryCamera.ZOOM_CAM
	| RobotNavCamera.NAV_CAM
	| 'audio';

export enum PilotPrimaryCamera {
	LOCAL = 'local',
}

export enum RobotPrimaryCamera {
	WIDE_CAM = 'wide_cam',
	ZOOM_CAM = 'zoom_cam',
}

export enum RobotNavCamera {
	NAV_CAM = 'nav_cam',
}

export enum DataChannels {
	NAV_DATACHANNEL = 'nav-datachannel',
	CONTROL_DATACHANNEL = 'control-datachannel',
	STATUS_DATACHANNEL = 'status-datachannel',
}

export type CloudLogLevel = 'log' | 'info' | 'debug' | 'error';

export type SessionType = 'normal' | 'reservation' | 'testing';

/** Session Info that is generate from Roster page */
export type SessionInfo = {
	awsClientConfiguration: AWSClientConfiguration;
	response: 'OK' | 'NOT_ALLOWED_BUSY' | 'NOT_ALLOWED_ERROR' | 'NOT_ALLOWED_NOT_READY';
	uuid: string;
	pilotId: string;
	robot: { id: string; name: string; serialNumber: string };
	pilot: { id: string; name: string; avatar?: string | null; language?: string };
	devices?: {
		camera?: { name?: string };
		microphone?: { name?: string };
		speaker?: { name?: string };
		microphoneVolume?: number;
		speakersVolume?: number;
	};
	sessionType?: SessionType;
	robotStatus?: RobotStatus;
	connectionsQuality?: ConnectionsQuality;
	capabilities: Capabilities;
	cloudLogLevel?: CloudLogLevel;
};

/** Gobe Web RTC session configuration */
export type GobeWebRTCSessionConfiguration = {
	webRTCSessionConfiguration: WebRTCSessionConfiguration;
	sessionType: SessionType;
	robotStatus: RobotStatus;
	connectionsQuality: ConnectionsQuality;
	cloudLogLevel?: CloudLogLevel;
	syncStreamDisplay?: boolean;
};

export type RobotStatus = { battery: Battery; network: Network; interface?: Interface };
export type ConnectionsQuality = {
	1: { dbm: number; quality: number };
	2: { dbm: number; quality: number };
};

export type Capabilities = {
	super_zoom?: boolean;
	super_zoom_pivot?: boolean;
	mouse_control_with_joystick: boolean;
	mouse_control_with_slider: boolean;
	wide_camera_rotation: 0 | 90 | 180 | 270;
	zoom_camera_rotation: 0 | 90 | 180 | 270;
	nav_camera_rotation: 0 | 90 | 180 | 270;
	nav_cam_deviation_percentage?: { x: number; y: number };
	wide_cam_deviation_percentage?: { x: number; y: number };
	wide_camera_crop: { top: number; right: number; bottom: number; left: number };
	zoom_cam_deviation_percentage?: { x: number; y: number };
	zoom_cam_position?: {
		deviation_percentage: { x: number; y: number };
		dimension_percentage: { width: number; height: number };
	};
	autoParkEnabled?: boolean;
	syncStreamDisplay?: boolean;
	robotLocalCameras?: boolean;
};

export type Battery = {
	level: string;
	charging: boolean;
};

export type Network = {
	id: number;
	quality: number;
	ssid: string | null;
	bssid: string | null;
	dbm: number;
};

export type Interface = {
	interface_id: string;
	interface_name: string;
	interface_type: string;
};

export type CustomIceServer = {
	url: string;
	username: string;
	password: string;
};

export type AWSClientConfiguration = {
	accessKeyId: string;
	secretAccessKey: string;
	iceTransportPolicy: IceTransportPolicy;
	iceTransportProtocol?: IceTransportProtocol;
	iceTransportProtocolRobot?: IceTransportProtocol;
	iceTransportProtocolPilot?: IceTransportProtocol;
	region: string;
	id: string;
	iceServers?: CustomIceServer[];
};

export type PeerConnectionEndReasonCode =
	| 'PEER_HANGUP'
	| 'LOCAL_HANGUP'
	| 'RESERVATION_ENDED'
	| 'CLEANUP'
	| 'FAILED_STATE_TIMED_OUT'
	| 'PAUSED_STATE_TIMED_OUT'
	| 'RETRY_TIMEOUT'
	| 'RETRY_FAILED'
	| 'MEDIA_ACCESS_ERROR'
	| 'NETWORK_FAILURE'
	| 'ROBOT_UNAVAILABLE'
	| 'USER_EARLY_CLOSE'
	| 'ERROR';

/** Representation of the state of the session. */
export type SessionState =
	| 'NotInitialized'
	| 'InProgress'
	| 'Paused'
	| 'SoftRetrying'
	| 'Retrying'
	| 'Ended';

export interface PrimaryCameraState {
	currentPrimaryCamera: RobotPrimaryCamera;
	isChangingPrimaryCameraTo: RobotPrimaryCamera | null;
}

export interface PivotState {
	currentPivot: {
		id: number;
		degrees: number;
	} | null;
	lastPivotResult: {
		id: number;
		pivotedDegrees: number;
		success: boolean;
		message: string;
	} | null;
}

export enum DataChannelMessageType {
	SHOW_STREAM = 'show_stream',
	ROBOT_STATUS = 'robot_status',
	HANG_UP = 'hang_up',
	REQUEST_CAMERA_SWITCH = 'request_camera_switch',
	CAMERA_SWITCH_RESPONSE = 'camera_switch_response',
	PAUSE = 'pause',
	RESUME = 'resume',
	PIVOT = 'pivot',
	SET_LOCAL_DISPLAYS = 'set_local_displays',
	STAIRS_DETECTED = 'stairs_detected',
	PILOT_ALLOW_MOVEMENT = 'pilot_allow_movement',
	SHARE_URL = 'share_url',
	WEBRTC_STATS_REPORT = 'webrtc_stats_report',
	CONNECTIONS_QUALITY = 'connections_quality',
	LASER_SCAN = 'laser_scan',
}

export const SOFT_RETRIES = 0;

export const SOFT_RETRY_DELAY_MS = 25000;

export type Stats = {
	succeededCandidatePair: {
		local: any;
		remote: any;
	};
	inboundRTP: {
		[RobotPrimaryCamera.WIDE_CAM]: {
			bitRate: { data: number[]; prevTimeStamp: number };
			fps: { data: number[] };
			packetsReceived: { data: number[] };
			packetLoss: { data: number[] };
			packetLossPercent: { data: number[] };
			currPointer: number;
			isFilled: boolean;
		};
		[RobotNavCamera.NAV_CAM]: {
			bitRate: { data: number[]; prevTimeStamp: number };
			fps: { data: number[] };
			packetsReceived: { data: number[] };
			packetLoss: { data: number[] };
			packetLossPercent: { data: number[] };
			currPointer: number;
			isFilled: boolean;
		};
		audio: {
			bitRate: { data: number[]; prevTimeStamp: number };
			packetsReceived: { data: number[] };
			packetLoss: { data: number[] };
			packetLossPercent: { data: number[] };
			currPointer: number;
			isFilled: boolean;
		};
	};
	outboundRTP: {
		[PilotPrimaryCamera.LOCAL]: {
			bitRate: { data: number[]; prevTimeStamp: number };
			fps: { data: number[] };
			currPointer: number;
			isFilled: boolean;
		};
		audio: {
			bitRate: { data: number[]; prevTimeStamp: number };
			currPointer: number;
			isFilled: boolean;
		};
	};
};

export const LOCAL_MEDIA_CONSTRAINTS = {
	audio: {
		sampleSize: 16,
		sampleRate: 48000,
		channelCount: { min: 1, max: 2, ideal: 2 },
		echoCancellation: true,
		autoGainControl: false,
		noiseSuppression: false,
	} as unknown as MediaStreamConstraints['audio'],
	video: {
		facingMode: 'user',
		width: 640,
		height: 480,
		frameRate: { max: 30 },
	} as MediaTrackConstraints,
};
